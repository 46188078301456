.sidewidth {
    width: calc(90% - 100px);
}

.css-i4bv87-MuiSvgIcon-root {
    height: 2rem;
    width: 2rem;
}

.scroolbar::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.scroolbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

/* Handle */
.scroolbar::-webkit-scrollbar-thumb {
    background: #3b32df;
    border-radius: 5px;
}

/* Handle on hover */
.scroolbar::-webkit-scrollbar-thumb:hover {
    background: #6627c5;
}
/*location*/
.hover-border:hover {
cursor: pointer;
/* border: 2px solid white;
width: 250px;
height: 80px; */
} 


/* For location popup */
      
      .popup-container {
        /* display: none; */
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
      }
      .popup {
        position: absolute;
        top: 480px;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        text-align: center;
        width: 25%;
      }
      .small-text {
        font-size: 0.8rem;
      }
      .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        font-size: 1.2rem;
        color: #999;
      }
      .close-button:hover {
        color: #333;
      }

      .icon-container {
        display: flex;
        flex-direction: row;
         /* Default gap */
      }
      
      @media (max-width: 1200px) {
        .icon-container {
          gap: 9px; /* Adjust gap for larger screens */
        }
      }
      @media (max-width: 1440px) {
        .icon-container {
          gap: 11px; /* Adjust gap for larger screens */
        }
      }
      @media (max-width: 2560px) {
        .icon-container {
          gap: 14px; /* Adjust gap for larger screens */
        }
      }
      
      @media (max-width: 992px) {
        .icon-container {
          gap: 0px; /* Adjust gap for medium screens */
        }
      }
      
      @media (max-width: 768px) {
        .icon-container {
          gap: -1px; /* Adjust gap for smaller screens */
        }
      }
      
      @media (max-width: 576px) {
        .icon-container {
          gap: 2px; /* Adjust gap for extra small screens */
        }
      }
      @media (max-width: 768px) {
        .toolbar-custom {
          gap: 25px !important; /* Adjust this value as needed */
        }
      }


/*  profile section styling*/
.edit-button {
  position: absolute;
  bottom: 1rem;
  left: 45rem; 
  background-color: transparent;
  color: #000000;
  padding: 0.25rem 0.75rem;
  border-radius: 0.7rem;
  cursor: pointer;
  border: 1px solid grey;
  transition: background-color 0.3s ease;
  font-size: 1rem;
  z-index: 10;
}

.edit-button:hover {
  background-color: #e4e4e4;
  color: rgb(0, 0, 0);
}

.review-button {
  position: absolute;
  bottom: 1rem;
  left: 34rem; 
  background-color: #ffd814;
  color: #030303;
  padding: 0.25rem 0.75rem;
  border-radius: 0.7rem;
  cursor: pointer;
  border: 1px solid #fcd200;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1rem;
  z-index: 10;
}

.review-button:hover {
  background-color: #dfbc0d;
  color: rgb(0, 0, 0);
   border: 1px solid #fcd200;
}

.profile-container {
  position: relative;
  width: 100%;
}

.hidden {
  display: none;
}
.profile-content {
  margin-left: 2rem; 
}
.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.profile-content {
  text-align: center;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}


.option-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid transparent; 
  transition: border-color 0.3s; 
}

.option-item:hover{
  
  border: 2px solid black;

}

.option-item .icon {
  width: 44px;
  height: 44px;
  border-radius: 15%;
}

/*  your adress section */

.box-with-line {
  position: relative;
}
.box-with-line::before {
  content: '';
  position: absolute;
  top: 40px; 
  left: 0;
  width: 100%;
  height: 1px; 
  background-color: black; 
}
.boxe {
  width: 320px;
  height: 320px;
  box-shadow: 2px 2px 10px rgba(0, 195, 255, 0.5);

}
.box-image {
  position: absolute;
  top:-32px;
  left: 120px; 
  width: 70px; 
  height: 70px; 
  overflow: hidden;
}
.box-image img {
  width: 100%;
  height: auto;
}
.hover-underline {
  font-size: 16px;
  text-decoration: none; 
  min-height: 50px !important;
}

.hover-underline:hover {
  text-decoration: underline !important; 
}


/*  new additional box your address */


.border-small-line {
  border-style: dashed;
  border-width: 2px;
  border-color: #6B7280;
}

.text-darkest {
  color: #000000;
}


.dialog-actions {
  display: flex;
  margin-right: 50px;
  gap: 0px; /* Adjust spacing between buttons */
}

.small-button {
  padding: 4px 10px; /* Adjust padding to make the buttons smaller */
  font-size: 0.75rem; /* Adjust font size if needed */
  width: 75px;
  height: 35px; /* Ensure minimum width for buttons */
}
.dialogs{
   width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 120px;
}
.dialogx{

  width: 300px;
  height: 400px;
}

#logout-dialog-description{
  font-weight: 600;
}




/*

Recent code

*/

.iwh{
  height: 3rem;
  width: 3rem;
}

/* Hide the location box on small screens */
@media (max-width: 768px) {
  #locationBox {
    display: none;
  }
}

/* Make the border appear only on hover */
#locationBox {
  border: none; /* No border by default */
}

#locationBox:hover {
  border: 2px solid white; /* Border appears on hover */
}

/* Extra Large Screens (Desktops above 1920px) */
@media (max-width: 2560px) {
  #locationBox {
    max-width: 380px; /* Slightly larger for extra large screens */
  }
  .locmedia {
    font-size: 1.3rem; /* Slightly larger font */
    line-height: 1.4rem; /* Increase line height for readability */
  }
  .updatelocation{
    font-weight: 700;
    font-size: 14px;
  }
}

/* Large Screens (Desktops between 1440px and 1920px) */
@media (max-width: 1920px) {
  #locationBox {
    max-width: 380px;
    padding-right: 5px;
  }
  .locmedia {
    font-size: 15px;
    line-height: 1.1rem;
  }
  .updatelocation{
    font-weight: 700;
    font-size: 14px;
  }
}

/* Medium Screens (Desktops between 1024px and 1440px) */
@media (max-width: 1440px) {
  #locationBox {
    max-width: 260px;
  }
  .locmedia {
    font-size: 0.875rem; /* Slightly smaller font */
    line-height: 1.1rem;
    width: 5rem;
  }
  .updatelocation{
    font-weight: 600;
    font-size: 10px;
  }
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
  #locationBox {
    max-width: 220px; /* Restrict width for proper wrapping */
  }
  .locmedia {
    font-size: 0.875rem; /* Slightly smaller font */
    line-height: 1.1rem; /* Adjust line height */
    width: 5rem;
  }
  .updatelocation{
    font-weight: 600;
    font-size: 10px;
  }
}

/* Small screens (phones) */
@media (max-width: 768px) {
  #locationBox {
    max-width: 200px; /* Further restrict the width */
  }
  .locmedia {
    font-size: 0.75rem; /* Smaller font size */
    line-height: 1rem; /* Compact line height */
    max-width: 180px; /* Restrict text width to ensure wrapping */
  }
}
