/* styles.css */
.style:hover{
    box-shadow: 2px 2px 10px rgba(0, 195, 255, 0.5);
}
.styles{

    /* border: 1px solid lightgrey; */
    box-shadow: 2px 2px 10px rgba(167, 167, 167, 0.5);
}

/* cart section add and move to wishlist button */

.icon-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.icon {
  fill: currentColor;
  transition: opacity 0.1s, color 0.1s;
}

.icon-wrapper:hover .icon {
  color: red; /* Change icon color on hover */
}
.icon-wrappers {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.icons {
  fill: currentColor;
  transition: opacity 0.1s, color 0.1s;
}

.icon-wrappers:hover .icons {
  color: rgb(0, 26, 255); /* Change icon color on hover */
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  color: #000; /* Text color */
  background-color: #fff; /* White background */
  border: 1px solid #000; /* Black border */
  text-align: center;
  border-radius: 2px;
  padding: 2px 5px; /* Smaller padding */
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  font-size: 12px; /* Smaller text */
  transition: opacity 0.1s, visibility 0.3s;
}

.icon-wrapper:hover .tooltip {
  visibility: visible;
  opacity: 1;
}
.tooltips {
  visibility: hidden;
  opacity: 0;
  color: #000; /* Text color */
  background-color: #fff; /* White background */
  border: 1px solid #000; /* Black border */
  text-align: center;
  border-radius: 2px;
  padding: 2px 5px; /* Smaller padding */
  position: absolute;
  z-index: 1;
  top: 125%;
  left: 50%;
  transform: translateX(-50%);
  width: 110px;
  font-size: 12px; /* Smaller text */
  transition: opacity 0.1s, visibility 0.1s;
}